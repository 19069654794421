import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { DeviceBrandSearchCriteria } from './models/DeviceBrandSearchCriteria';
import { DeviceBrandDto } from './models/DeviceBrandDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class DeviceBrandsService {
    public getList(criteria: DeviceBrandSearchCriteria) {
        return Http.get<Paged<DeviceBrandDto>>('devicebrands', criteria);
    }

    public getById(id: string) {
        return Http.get<DeviceBrandDto>('devicebrands/' + id);
    }

    public getDeviceBrands() {
        return Http.get<SelectValueLabel[]>('devicebrands/getdevicebrands');
    }

    public create(model: DeviceBrandDto) {
        return Http.post<string>('devicebrands', model);
    }

    public update(model: DeviceBrandDto) {
        console.log(model);
        return Http.put<string>('devicebrands/' + model.id, model);
    }

    public remove(model: DeviceBrandDto) {
        return Http.put(`devicebrands/${model.id}/deactivate`, model);
    }
}

export default new DeviceBrandsService();
