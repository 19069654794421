/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { UserDto } from 'api/users/models/UserDto';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './UserScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import CustomFile from 'common/models/CustomFile';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import RolesService from 'api/roles/RolesService';
import { RoleDto } from 'api/roles/models/RoleDto';

type Props = {
};

const UserScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['USERS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const [medias, setMedias] = useState<CustomFile[]>([]);
    const [allRoles, setAllRoles] = useState<RoleDto[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<UserDto>();
    const [formData, setFormData] = useState({
        id: '',
        realName: '',
        email: '',
        roles: [],
        temporaryRoles: [] as any[]
    });

    const getData = async () => {
        try {
            let result: any = { id: '', realName: '', email: '', roles: [], temporaryRoles: [] };
            if (id) {
                Loading.show();
                const rolesList = await RolesService.getAll();
                setAllRoles([...rolesList]);
                const _result = await UsersService.getById(id);
                _result.temporaryRoles = rolesList.map(role => {
                    const _role = { ...role };
                    _role.checked = (_result.roles?.map(r => r.id).includes(_role.id) ?? false);
                    return _role;
                });
                _result.temporaryRoles.forEach(role => {
                    if (role.readOnly || role.system) {
                        role.name = t(('security.roles.roles_list.' + role.name) as any);
                    }
                });

                result = _result;
                Loading.hide();
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get user information', error);
            toast.error(t('messages.error_load_info'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    function handleCheck(index: number, value: boolean) {
        formData.temporaryRoles[index].checked = value;
        setFormData({ ...formData });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/security/users/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/security/users');
        }
    }
    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete company', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onAddMedias = (medias: CustomFile[]) => {
        setMedias(medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        const filteredMedia = [...medias.filter(x => x.id !== mediaId)];
        setMedias(filteredMedia);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: UserDto = {
                id: formData.id,
                realName: formData.realName,
                email: formData.email,
                roles: [],
                temporaryRoles: []
            };

            if (formData.temporaryRoles) {
                formData.temporaryRoles.forEach((r, i) => {
                    if (r.checked) {
                        model.roles?.push(allRoles[i]);
                    }
                });
            }

            if (model && model.id) {
                await UsersService.update(model, medias)
                navigateTo('details', id);
            } else if (model) {
                const id = await UsersService.create(model, medias)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast(t('messages.user_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the user with id: ${form.id}`, error);
                toast.error(t('messages.record_save_error'));
            }
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('security.users.title')}>
            <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Row>
                    <Col xs={10} >
                        <Col xs={12} >
                            <Form.Group className="mb-3" controlId="realName">
                                <Form.Label>{t('security.users.name')}{!isDetails ? '*' : ''}</Form.Label>
                                <Form.Control required name="realName" type="text" defaultValue={item?.realName} readOnly={isDetails} onChange={handleChange} placeholder={t('security.users.name')} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} >
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>{t('security.users.email')}{!isDetails ? '*' : ''}</Form.Label>
                                <Form.Control required name="email" defaultValue={item?.email} type="text" readOnly={isDetails} onChange={handleChange} placeholder={t('security.users.email')} />
                            </Form.Group>
                        </Col>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>{t('security.users.photo')}</Form.Label>
                        <MediaSelector
                            isMulti={false}
                            isDetails={isDetails}
                            onAddMedias={onAddMedias}
                            onRemoveMedia={onRemoveMedia}
                            initialMedias={item?.medias ?? []} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Label>{t('security.roles.title')}</Form.Label>
                        {item?.temporaryRoles.map((role, i) => {
                            return (<Form.Check type="switch" checked={role.checked} key={i} name={`temporaryRoles[${i}]`} disabled={type === 'details'} label={role.name} onChange={e => handleCheck(i, e.target.checked)} />);
                        })}
                    </Col>
                </Row>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasUsersWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as UserDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && hasUsersWritePolicy &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit', item?.id); }}>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && hasUsersWritePolicy &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.realName ?? '' })} />
        </ScreenTitle>
    );
};

export default UserScreen;
