import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { PlanSearchCriteria } from './models/PlanSearchCriteria';
import { PlanDto } from './models/PlanDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class PlansService {
    public getList(criteria: PlanSearchCriteria) {
        return Http.get<Paged<PlanDto>>('plans', criteria);
    }

    public getById(id: string) {
        return Http.get<PlanDto>('plans/' + id);
    }

    public getPlans() {
        return Http.get<SelectValueLabel[]>('plans/getplans');
    }

    public create(model: PlanDto) {
        return Http.post<string>('plans', model);
    }

    public update(model: PlanDto) {
        console.log(model);
        return Http.put<string>('plans/' + model.id, model);
    }

    public remove(model: PlanDto) {
        return Http.put(`plans/${model.id}/deactivate`, model);
    }
}

export default new PlansService();
