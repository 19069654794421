import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { DeviceSearchCriteria } from './models/DeviceSearchCriteria';
import { DeviceDto } from './models/DeviceDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class DevicesService {
    public getList(criteria: DeviceSearchCriteria) {
        return Http.get<Paged<DeviceDto>>('devices', criteria);
    }

    public getById(id: string) {
        return Http.get<DeviceDto>('devices/' + id);
    }

    public getMyDevices() {
        return Http.get<SelectValueLabel[]>('Devices/getDevices');
    }

    public create(model: DeviceDto) {
        return Http.post<string>('devices', model);
    }

    public update(model: DeviceDto) {
        return Http.put<string>('devices/' + model.id, model);
    }

    public remove(model: DeviceDto) {
        return Http.put(`devices/${model.id}/deactivate`, model);
    }
}

export default new DevicesService();
