import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { CompanySearchCriteria } from './models/CompanySearchCriteria';
import { CompanyDto } from './models/CompanyDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class CompaniesService {
    public getList(criteria: CompanySearchCriteria) {
        return Http.get<Paged<CompanyDto>>('companies', criteria);
    }

    public getById(id: string) {
        return Http.get<CompanyDto>('companies/' + id);
    }

    public getCompanies() {
        return Http.get<SelectValueLabel[]>('companies/getcompanies');
    }

    public getCurrent() {
        return Http.get<CompanyDto>('companies/current');
    }

    public create(model: CompanyDto, attachment: CustomFile[]) {
        return Http.postWithFile<string>('companies', model, attachment);
    }

    public update(model: CompanyDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('companies', model, attachment);
    }

    public remove(model: CompanyDto) {
        return Http.put('companies/deactivate', model);
    }
}

export default new CompaniesService();
