import { useEffect, useRef, useState } from 'react';
import Events from '../../services/Events';
import LoadingSpinner from './LoadingSpinner';
import styles from './LoadingProvider.module.scss';

function LoadingProvider (): JSX.Element | null {
    const [showLoading, setShowLoading] = useState(false);
    const isComponentMounted = useRef(false);
    const loaded = useRef(false);

    useEffect(() => {
        if (!loaded.current) {
            isComponentMounted.current = true;
            loaded.current = true;

            Events.addListener('loading', (loading: boolean) => {
                if (isComponentMounted.current) {
                    setShowLoading((s) => {
                        if (s !== loading) {
                            return loading;
                        }
                        return s;
                    });
                }
            });
        }

        return () => {
            isComponentMounted.current = false;
            loaded.current = false;
        };
    }, []);

    if (showLoading) {
        return (
            <div className={styles.container}>
                <LoadingSpinner />
            </div>
        );
    }

    return null;
}

export default LoadingProvider;
