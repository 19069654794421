import Http from '../../common/services/Http';
import { RuleDto } from './models/RuleDto';

class RulesService {
    public getAllForSelectItem () {
        return Http.get<RuleDto[]>('rules/get-All-for-select-item');
    }

    public update (model: RuleDto[]) {
        return Http.put('rules', { rules: model });
    }
}

export default new RulesService();
