import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import { Breadcrumb, Tab, Tabs } from 'react-bootstrap';
import UserScreen from './components/user/UserScreen';
import UsersScreen from './components/users/UsersScreen';
import RolesScreen from './components/roles/RolesScreen';
import RoleScreen from './components/role/RoleScreen';
import RulesScreen from './components/rules/RulesScreen';

const SecurityScreen = () => {
    const { t } = useTranslation();
    const { tabId, type, id } = useParams<{ tabId: string, type: string, id: string }>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['USERS_READ']);
    const hasRolesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['ROLES_READ']);
    const hasRulesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['RULES_READ']);
    const defaultTab = tabId || (hasUsersReadPolicy ? 'users' : (hasRolesReadPolicy ? 'roles' : 'rules'));

    return (
        <ScreenTitle title={t('security.title')}>
            <Breadcrumb>
                <Breadcrumb.Item active>
                    {t('security.title')}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Tabs defaultActiveKey={defaultTab} className="mb-3" justify>
                <Tab eventKey="users" title={t('security.users.title')}>
                    {tabId === 'users' && type ? <UserScreen /> : <UsersScreen />}
                </Tab>
                <Tab eventKey="roles" title={t('security.roles.title')}>
                    {tabId === 'roles' && type ? <RoleScreen /> : <RolesScreen />}
                </Tab>
                <Tab eventKey="rules" title={t('security.rules.title')}>
                    <RulesScreen />
                </Tab>
            </Tabs>
        </ScreenTitle>
    );
}

export default SecurityScreen;
