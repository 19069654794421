/* eslint-disable @typescript-eslint/indent */
import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';

const RoutingMachineLayer = (props: any) => {
  const { waypoints, renderInfo, loadIcon, hide } = props;
  const plan = new L.Routing.Plan(waypoints, {
    createMarker: (i, wp, nWps) => {
      const icon = new L.Icon({
        iconUrl: loadIcon(i),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      if (hide && i > 0 && i < waypoints.length - 1) {
        return false;
      }
      return L.marker(wp.latLng, { icon })
        .bindPopup(() => {
          const div = document.createElement('div');
          const root = createRoot(div);
          flushSync(() => {
            if (renderInfo) {
              root.render(renderInfo(i));
            }
          });
          return div.innerHTML;
        });
    }
  });

  const instance = L.Routing.control({
    waypoints: waypoints,
    show: false,
    autoRoute: false,
    addWaypoints: true,
    routeWhileDragging: false,
    fitSelectedRoutes: true,
    showAlternatives: false,
    plan,
    // lineOptions: { extendToWaypoints: false, missingRouteTolerance: 0, styles: [{ color: 'white', opacity: 0, weight: 9 }] }
  });
  return instance;
};

const RoutingMachine = createControlComponent(RoutingMachineLayer);

export default RoutingMachine;
