import { UserProfile } from '../../api/account/models/UserProfile';
import { UserToken } from '../../api/account/models/UserToken';

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN';

export interface Action {
    type: string
    [propName: string]: any
}

export interface AuthenticationState {
    isAuthenticated: boolean
    token: UserToken | null
    profile: UserProfile | null
    refreshToken: number
}
