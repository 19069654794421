import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleSearchCriteria } from './models/VehicleSearchCriteria';
import { VehicleDto } from './models/VehicleDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class VehiclesService {
    public getList(criteria: VehicleSearchCriteria) {
        return Http.get<Paged<VehicleDto>>('vehicles', criteria);
    }

    public getById(id: string) {
        return Http.get<VehicleDto>('vehicles/' + id);
    }

    public getMyVehicles() {
        return Http.get<SelectValueLabel[]>('Vehicles/getVehicles');
    }

    public create(model: VehicleDto) {
        return Http.post<string>('vehicles', model);
    }

    public update(model: VehicleDto) {
        return Http.put<string>('vehicles/' + model.id, model);
    }

    public remove(model: VehicleDto) {
        return Http.put(`vehicles/${model.id}/deactivate`, model);
    }
}

export default new VehiclesService();
