import useIsMobile from 'common/hooks/useIsMobile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import styles from './QuestionYesNo.module.scss';
import { Button, Form } from 'react-bootstrap';

interface Props {
    message?: string | null;
    messageLine2?: string | null;
    isVisible: boolean;
    onYes: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onNo: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactElement;
    isInformation?: boolean | null;
}

const QuestionYesNo: React.FC<Props> = ({ message, messageLine2, isVisible, onYes, onNo, children, isInformation }: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const customStyles = {
        content: {
            top: '15%',
            left: isMobile ? '10%' : '35%',
            right: isMobile ? '10%' : '35%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',
            padding: '10px',
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '1001'
        }
    };

    return (
        <Modal
            isOpen={isVisible}
            style={customStyles}
        >
            <div>
                <div className={styles.titleContainer}>
                    <Form.Label>{!isInformation ? t('common.question') : t('common.information')}</Form.Label>
                </div>
                {message && <div className={styles.messageContainer}>
                    <label className={styles.textMessage}>{message}</label>
                    {messageLine2 && <label className={styles.textMessage}><br/>{messageLine2}</label>}
                </div>}
                {children}
                <div className={styles.buttons_container} >
                    <Button onClick={onNo} variant='secondary' className={styles.button}>
                        {isInformation ? t('common.ok') : t('common.no')}
                    </Button>
                    {!isInformation && <Button onClick={onYes} variant='primary' className={styles.button}>
                        {t('common.yes')}
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default QuestionYesNo;
