/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import { PlanDto } from 'api/plans/models/PlanDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Button, Card, Container, Dropdown, DropdownButton, Form, Stack } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './PlanScreen.module.scss';
import PlansService from 'api/plans/PlansService';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Paged } from 'api/common/types/Page';
import { PlanSearchCriteria } from 'api/plans/models/PlanSearchCriteria';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

type Props = {
};

const PlanScreen: React.FC<Props> = () => {
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasPlansWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CONFIG_DEVICE_BRAND_WRITE']);
    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<PlanDto>();
    const [refresh, setRefresh] = useState<number>(1);
    const initial = { id: '', name: '', rowVersion: '' };
    const [formData, setFormData] = useState(initial);
    const [criteria, setCriteria] = useState<PlanSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'p.name'
    });
    const [plansPage, setPlansPage] = useState<Paged<PlanDto>>();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<PlanDto | null>(null);

    const showRemoveItemDialog = async (item: PlanDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }
        try {
            await PlansService.remove(itemToRemove);
            onCancelRemove();
            toast.success(t('messages.record_delete_success'));
            clear();
            setCriteria({ ...criteria, page: 1 });
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete plans', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    function clear() {
        setItem(initial);
        setFormData(initial);
        setValidated(false);
        setRefresh(refresh + 1);
    }

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: PlanDto = {
                id: formData.id,
                name: formData.name,
                rowVersion: formData.rowVersion,
            };
            if (model && model.id) {
                await PlansService.update(model);
            } else if (model) {
                await PlansService.create(model);
            }
            Loading.hide();
            clear();
            setCriteria({ ...criteria, page: 1 });
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the plan with id: ${form.id}`, error);
            toast.error(t('messages.error_load_info'));
            Loading.hide();
        }
    };

    const renderTableActionCell = (row: PlanDto) => {
        return <DropdownButton id="dropdown-basic-button" title="..." size="sm" variant="outline-secondary">
            {hasPlansWritePolicy && <>
                <Dropdown.Item onClick={() => { setItem(row); setFormData({ id: row.id ?? '', name: row.name, rowVersion: row.rowVersion ?? '' }); }}>{t('common.edit')}</Dropdown.Item>
                <Dropdown.Item onClick={() => showRemoveItemDialog(row)}>{t('common.delete')}</Dropdown.Item>
            </>
            }
        </DropdownButton>
    };

    useEffect(() => {
        search().catch(console.error)
    }, [criteria]);

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<PlanDto>[] = [
        {
            field: 'name',
            name: t('plans.list.name') ?? '',
            onSearch: onTableFilter,
            searchField: 'p.name'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const search = async () => {
        try {
            Loading.show();
            const page = await PlansService.getList(criteria);
            setPlansPage(page);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get plan list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    return (<>
        <Container fluid>
            <Card className={styles.filters}>
                <Card.Body>
                    <Form onSubmit={onSubmit} noValidate validated={validated} key={item?.name}>
                        <Stack direction="horizontal" gap={3} key={refresh}>
                            <Form.Control required name="name" type="text" defaultValue={item?.name} onChange={handleChange} placeholder={t('plans.name')} />
                            <div className="vr" />
                            <Button variant="outline-danger" onClick={clear}>{t('common.clear')}</Button>
                            <Button variant="primary" type="submit" onClick={onSubmit}>{t('common.save')}</Button>
                        </Stack>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        <Container fluid className='mt-5'>
            <ListingTable
                columns={tableColumns}
                rows={plansPage?.items || []}
                allowHover={true}
                initialSearch={{ colField: 'db.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('plans.title')}
                currentPage={plansPage?.currentPage ?? 1}
                pageItems={plansPage?.items.length || 0}
                totalItems={plansPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })} />
        </Container>
        <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })} />
        </>);
};

export default PlanScreen;
