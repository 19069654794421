/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import DatePicker, { registerLocale, ReactDatePickerProps as DateProps } from 'react-datepicker';
import styles from './DateTimePicker.module.scss'
import { fr, pt, enGB } from 'date-fns/locale';
import { DEFAULT_LANGUAGE, STORAGE } from 'Config';
import Storage from '../../services/Storage';
registerLocale('fr', fr)
registerLocale('pt', pt)
registerLocale('enGB', enGB)

export type Props = {
} & DateProps;

const DateTimePicker = React.forwardRef<DatePicker, Props>(({ ...props }, ref) => {
  const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;

  return (
    <DatePicker
      locale={locale}
      {...props}
      ref={ref}
      showYearDropdown
      wrapperClassName={styles.fullWidth}
    />
  );
});

export default DateTimePicker;
