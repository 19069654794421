import { Badge, Card, Col, Container, Form, InputGroup, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import styles from './VehicleLocationScreen.module.scss';
import { MapContainer, Popup, TileLayer, CircleMarker, Polyline, Circle } from 'react-leaflet';
import MarkerImage from 'assets/img/marker-icon-2x.png';
import RedMarkerImage from 'assets/img/red_marker-icon-2x.png';
import GreenMarkerImage from 'assets/img/green_marker-icon-2x.png';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import Loading from 'common/services/Loading';
import { useEffect, useState } from 'react';
import Logger from 'common/services/Logger';
import { DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE, googleMapsQuery } from 'Config';
import toast from 'react-hot-toast';
import { TrackingSearchCriteria } from 'api/trackings/models/TrackingSearchCriteria';
import { TrackingDto } from 'api/trackings/models/TrackingDto';
import TrackingsService from 'api/trackings/TrackingsService';
import RoutingMachine from 'common/components/maps/RoutineMachine';
import { TrackingRouteDto } from 'api/trackings/models/TrackingRouteDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { useGeoLocator, addressFormat } from 'common/services/GeoLocator';
import { faLocationDot, faCalendarDays, faGaugeHigh, faClock, faCar, faCarOn, faLeaf, faGasPump, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import DistanceFormat from 'common/components/distanceFormat/DistanceFormat';
import { NumericFormat } from 'react-number-format';
import { FaExternalLinkAlt } from 'react-icons/fa';

type Props = {
    startDate: Date | null,
    vehicleId: string;
};

const VehicleLocationScreen: React.FC<Props> = ({ vehicleId, startDate }: Props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<number>(1);
    const [position, setPosition] = useState<TrackingDto>();
    const [trackingRoutes, setTrackingRoutes] = useState<TrackingRouteDto[]>([]);
    const [waypoints, setWaypoints] = useState<L.LatLng[]>([]);
    const [selectedRouteKey, setSelectedRouteKey] = useState<string>('');
    const [waypointsInfo, setWaypointsInfo] = useState<TrackingDto[]>([]);
    const redOptions = { color: 'red' }
    const [hidePoints, setHidePoints] = useState<boolean>(false);
    const [criteria] = useState<TrackingSearchCriteria>({
        itemsPerPage: 500,
        page: 1,
        orderBy: 'desc',
        orderColumn: 'start_date',
        date: startDate,
        vehicleId: vehicleId
    });

    const search = async () => {
        try {
            Loading.show();
            const position = await TrackingsService.getPosition(criteria);
            setPosition(position);
            const routes = await TrackingsService.getRoutes(criteria);
            setTrackingRoutes(routes);
            setWaypoints([]);
            setWaypointsInfo([]);
            setSelectedRouteKey('');
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const loadWaypoints = async (start: number, stop: number) => {
        const _criteria = { ...criteria, start, stop };
        const _waypoints = await TrackingsService.getWaypoints(_criteria);
        const waypoints: L.LatLng[] = [];
        if (_waypoints) {
            _waypoints.forEach(i => {
                waypoints.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
            });
        }
        setSelectedRouteKey(`georoute_${start}_${stop}`)
        setWaypointsInfo(_waypoints);
        setWaypoints(waypoints);
        setRefresh(refresh + 1);
    }

    const renderCurrentPositionTooltip = (props: any) => (
        <Tooltip {...props}>
            {t('location.current')}
        </Tooltip>
    );

    const renderEcoValueTooltip = (props: any, value: number) => (
        <Tooltip {...props}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Badge bg={calcEcoValue(0)}><FontAwesomeIcon icon={faLeaf} className='mx-1' />{t('location.eco')}</Badge>
                <p>{t(`location.eco_${calcEcoValue(0)}`)}</p>
                <Badge bg={calcEcoValue(6)}><FontAwesomeIcon icon={faLeaf} className='mx-1' />{t('location.eco')}</Badge>
                <p>{t(`location.eco_${calcEcoValue(6)}`)}</p>
                <Badge bg={calcEcoValue(15)}><FontAwesomeIcon icon={faLeaf} className='mx-1' />{t('location.eco')}</Badge>
                <p>{t(`location.eco_${calcEcoValue(15)}`)}</p>
            </div>
        </Tooltip>
    );

    const moveToCurrent = () => {
        setSelectedRouteKey('');
        setWaypointsInfo([]);
        setWaypoints([]);
        setRefresh(refresh + 1);
    }

    useEffect(() => {
        search().catch(console.error);
    }, [criteria]);

    const onMovementLabel = (item: TrackingDto) => {
        if (item.movement ?? false) {
            return t('common.yes');
        } else {
            return t('common.no');
        }
    }

    const onIgnitionOnLabel = (item: TrackingDto) => {
        if (isIgnitionOn(item)) {
            return t('common.yes');
        } else {
            return t('common.no');
        }
    }

    const isIgnitionOn = (item: TrackingDto) => {
        return (item.ignition ?? false);
    }

    const renderInfo = (i: number) => {
        if (waypointsInfo[i]) {
            return <>
                <h6>{t('location.date')}: <Badge bg="secondary"><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={waypointsInfo[i].date} /></Badge></h6>
                <h6>{t('location.latitude')}: <Badge bg="secondary">{waypointsInfo[i].latitude ?? 0}</Badge></h6>
                <h6>{t('location.longitude')}: <Badge bg="secondary">{waypointsInfo[i].longitude ?? 0}</Badge></h6>
                <h6>{t('location.speed')}: <Badge bg="secondary">{waypointsInfo[i].speed ?? 0} Km/h</Badge></h6>
                <h6>{t('location.km')}: <Badge bg="secondary"><DistanceFormat value={(waypointsInfo[i].km ?? 0)} /></Badge></h6>
                <h6>{t('location.ignition')}: <Badge bg="secondary">{onIgnitionOnLabel(waypointsInfo[i])}</Badge></h6>
                <h6>{t('location.movement')}: <Badge bg="secondary">{onMovementLabel(waypointsInfo[i])}</Badge></h6>
            </>
        }
        return <></>
    }

    const loadIcon = (i: number) => {
        if (waypointsInfo) {
            if (i < 1) {
                return RedMarkerImage;
            } else if (i > 0 && i < waypointsInfo.length - 1) {
                return MarkerImage;
            } else {
                return GreenMarkerImage;
            }
        }
        return MarkerImage;
    }

    const calcEcoValue = (value: number) => {
        if (value <= 5) {
            return 'success';
        } else if (value > 5 && value < 15) {
            return 'warning';
        } else {
            return 'danger';
        }
    }

    const GeoPosition = ({ latitude, longitude, icon }: { latitude: number, longitude: number, icon: string }) => {
        const { t } = useTranslation();
        const link = googleMapsQuery(latitude,longitude);
        const { isLoading, error, data } = useGeoLocator(latitude, longitude);
        const hasValidInfo = (data: any) => {
            return data && (data.display_name || (data.results && data.results.length));
        }
        const renderNoAddress = () => <InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <div className={styles.labelAddress}>
                <a href={link}>{t('alarm_settings.notifications.open_location')}
                    <span className={styles.linkIcon}>
                        <FaExternalLinkAlt/>
                    </span>
                    <span className={styles.small}>({`${latitude ?? 0},${longitude ?? 0}`})</span>
                </a>
            </div>
        </InputGroup>;

        const renderAddress = (data: any) => <InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <Form.Text>{addressFormat(data)}</Form.Text>
        </InputGroup>;
        if (isLoading) return renderNoAddress();
        if (error) console.log('An error occurred while fetching the user data ', error);
        return hasValidInfo(data) ? renderAddress(data) : renderNoAddress();
    };

    const GeoLocatorItem = ({ id, latitude, longitude, fuelLevel, icon, address }: { id: number, latitude: number, longitude: number, fuelLevel: number, icon: string, address: string }) => {
        let formatedAddress = '';
        if (address) {
            formatedAddress = address;
        } else {
            const { isLoading, error, data } = useGeoLocator(latitude, longitude);
            if (isLoading) return <LoadingSpinner />;
            if (error) console.log('An error occurred while fetching the user data ', error);
            formatedAddress = addressFormat(data);
            try {
                TrackingsService.updateAddress(id, formatedAddress).catch(console.error);
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update route address', error);
            }
        }
        return (<InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <Form.Control size="sm" type='text' value={formatedAddress} onChange={() => { }} />
           {fuelLevel > 0 && <div className={styles.iconGas}>
                <FontAwesomeIcon icon={faGasPump} />&nbsp; <NumericFormat displayType='text' decimalScale={0} suffix='%' value={fuelLevel} />
            </div>}
        </InputGroup>);
    };

    const GeoRoutes = ({ start, stop, startLatitude, startLongitude, stopLatitude, stopLongitude, startDate, stopDate, startKm, stopKm, startFuelLevel, stopFuelLevel, ecoValue, idling,speed, startAddress, stopAddress }:
        { start: number, stop: number, startLatitude: number, startLongitude: number, stopLatitude: number, stopLongitude: number, startKm: number, stopKm: number, startFuelLevel: number, stopFuelLevel: number, startDate: Date, stopDate: Date, ecoValue: number, idling: number, speed: number, startAddress: string, stopAddress: string }) => {
        const diffInTime = new Date(stopDate).getTime() - new Date(startDate).getTime();
        const hours = Math.floor(diffInTime / (1000 * 60 * 60))
        const minutes = Math.round((diffInTime - (hours * 1000 * 60 * 60)) / (1000 * 60));

        const idlingHours = Math.floor(idling / 3600)
        const idlingMinutes = Math.round((idling - (idlingHours * 3600)) / 60);

        const kms = Math.round(stopKm - startKm);
        return (
            <Card border={selectedRouteKey === `georoute_${start}_${stop}` ? 'info' : 'secondary'} className={styles.routeItem} onClick={() => loadWaypoints(start, stop)} key={`georoute_${start}_${stop}`}>
                <Card.Header bsPrefix={selectedRouteKey === `georoute_${start}_${stop}` ? styles.cardHeaderSelected : styles.cardHeader}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <Form.Text muted><FontAwesomeIcon icon={faCalendarDays} className='mx-1' /><strong>{t('location.start')}</strong>&nbsp;<DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={startDate} /></Form.Text>
                        <Form.Text muted><FontAwesomeIcon icon={faCalendarDays} className='mx-1' /><strong>{t('location.end')}</strong>&nbsp;<DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={stopDate} /></Form.Text>
                    </div>
                </Card.Header>
                <Card.Body>
                    <GeoLocatorItem id={start} latitude={startLatitude} longitude={startLongitude} icon={styles.sourcePin} fuelLevel={startFuelLevel} address={startAddress}/>
                    <GeoLocatorItem id={stop} latitude={stopLatitude} longitude={stopLongitude} icon={styles.destinationPin} fuelLevel={stopFuelLevel} address={stopAddress}/>
                </Card.Body>
                <Card.Footer bsPrefix={selectedRouteKey === `georoute_${start}_${stop}` ? styles.cardFooterSelected : styles.cardFooter}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <Form.Text muted><FontAwesomeIcon icon={faGaugeHigh} className='mx-1' /><strong><DistanceFormat value={kms} /></strong></Form.Text>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(props) => renderEcoValueTooltip(props, ecoValue)}>
                            <Badge bg={calcEcoValue(ecoValue)} className={styles.badge}><FontAwesomeIcon icon={faLeaf} className='mx-1' />{t('location.eco')}</Badge>
                        </OverlayTrigger>
                        { (speed > 5) && <Badge bg='info' className={styles.badge}><FontAwesomeIcon icon={faCar} className='mx-1' />{t('location.in_transit')}</Badge>}
                        <Form.Text muted><FontAwesomeIcon icon={faHourglass} className='mx-1' /><strong>{idlingHours.toString().padStart(2, '0')}:{idlingMinutes.toString().padStart(2, '0')}h</strong></Form.Text>
                        <Form.Text muted><FontAwesomeIcon icon={faClock} className='mx-1' /><strong>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}h</strong></Form.Text>
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const renderMap = () => {
        const zoomPosition = waypointsInfo && waypointsInfo.length > 0 ? waypointsInfo[waypointsInfo.length - 1] : position;
        if (position && zoomPosition) {
            return <>
                <MapContainer center={[zoomPosition.latitude ?? 0, zoomPosition.longitude ?? 0]} maxZoom={18} minZoom={6} zoom={12} scrollWheelZoom={true} className={styles.map}>
                    <TileLayer
                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      // url="https://{s}-tiles.locationiq.com/v3/streets/r/{z}/{x}/{y}.png?key=pk.bedcfcc8edcc23aac005219737d079e5"
                    />
                    {!(waypointsInfo && waypointsInfo.length > 0) && <CircleMarker center={[position.latitude ?? 0, position.longitude ?? 0]} pathOptions={{ fillColor: 'blue' }} radius={25}>
                        <Popup>
                            <h6>{t('location.date')}: <Badge bg="secondary"><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={position.date} /></Badge></h6>
                            <h6>{t('location.latitude')}: <Badge bg="secondary">{position.latitude ?? 0}</Badge></h6>
                            <h6>{t('location.longitude')}: <Badge bg="secondary">{position.longitude ?? 0}</Badge></h6>
                            <h6>{t('location.speed')}: <Badge bg="secondary">{position.speed ?? 0} Km/h</Badge></h6>
                            <h6>{t('location.km')}: <Badge bg="secondary"><DistanceFormat value={(position.km ?? 0)} /></Badge></h6>
                            <h6>{t('location.ignition')}: <Badge bg="secondary">{onIgnitionOnLabel(position)}</Badge></h6>
                            <h6>{t('location.movement')}: <Badge bg="secondary">{onMovementLabel(position)}</Badge></h6>
                        </Popup>
                    </CircleMarker>}
                    <RoutingMachine waypoints={waypoints} renderInfo={renderInfo} loadIcon={loadIcon} hide={hidePoints} />
                    <Polyline pathOptions={redOptions} positions={waypoints} />
                </MapContainer></>
        } else {
            return <></>
        }
    }

    return (<>
        <Card className={styles.currentPositionCard}>
            <Card.Body>
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2" onClick={() => moveToCurrent()}>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderCurrentPositionTooltip}>
                            <Row>
                                <Col xs={12}>
                                    {position && <GeoPosition latitude={position.latitude ?? 0} longitude={position.longitude ?? 0} icon={styles.currentPin} />}
                                </Col>
                            </Row>
                        </OverlayTrigger>
                    </div>
                    <div className="ms-auto" onClick={() => moveToCurrent()}>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderCurrentPositionTooltip}>
                            <Row>
                                <Col xs={12}>
                                    {position && <Badge bg={isIgnitionOn(position) ? 'secondary' : 'danger'}>{isIgnitionOn(position) ? <>{t('location.on')}<FontAwesomeIcon icon={faCarOn} className='mx-1' /></> : <>{t('location.off')}<FontAwesomeIcon icon={faCar} className='mx-1' /></>}</Badge>}
                                </Col>
                            </Row>
                        </OverlayTrigger>
                    </div>
                    <div className="vr" />
                    <Form.Check type="switch" label={t('location.hide_points')} onChange={(e) => { setHidePoints(e.target.checked); setRefresh(refresh + 1); }} />
                </Stack>
            </Card.Body>
        </Card>
        <Row>
            {trackingRoutes && trackingRoutes.length > 0 && <Col xs={12} md={5}>
                <Container fluid className={styles.geoLocation}>
                    {trackingRoutes.map((route, i) => <GeoRoutes key={i} start={route.start} stop={route.stop} startLatitude={route.startLatitude} startLongitude={route.startLongitude} stopLatitude={route.stopLatitude} stopLongitude={route.stopLongitude} startDate={route.startDate} stopDate={route.stopDate} startKm={route.startKm} stopKm={route.stopKm} startFuelLevel={route.startFuelLevel} stopFuelLevel={route.stopFuelLevel} ecoValue={route.ecoValue} idling={route.idling} speed={route.stopSpeed} startAddress={route.startAddress} stopAddress={route.stopAddress}/>)}
                </Container>
            </Col>}
            <Col xs={12} md={trackingRoutes && trackingRoutes.length > 0 ? 7 : 12}>
                <Container fluid className={styles.map} key={`map_${refresh}`}>
                    {renderMap()}
                </Container>
            </Col>
        </Row>
    </>);
}

export default VehicleLocationScreen;
