import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';

export type Props = {
    onZoomChange: (level: number) => void;
    level: number;
  };

const ZoomLevelControl = ({ onZoomChange, level }: Props) => {
    const [zoomLevel, setZoomLevel] = useState(level);

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });
    onZoomChange(zoomLevel);

    return <></>
  }

  export default ZoomLevelControl;
