/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Breadcrumb, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import DeviceBrandScreen from './deviceBrands/DeviceBrandScreen';
import DeviceModelScreen from './deviceModels/DeviceModelScreen';
import { useState } from 'react';

type Props = {
};

const ConfigDevicesScreen: React.FC<Props> = () => {
    const { t } = useTranslation();
    const [modelVersion, setModelVersion] = useState<number>(1);

    return (
        <ScreenTitle title={t('config_devices.title')}>
            <Breadcrumb>
                <Breadcrumb.Item active>{t('config_devices.title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs defaultActiveKey="brands" className="mb-3" fill>
                <Tab eventKey="brands" title={t('config_devices.brands.title')}>
                    <DeviceBrandScreen onSave={() => setModelVersion(modelVersion + 1)}/>
                </Tab>
                <Tab eventKey="models" title={t('config_devices.models.title')}>
                    <DeviceModelScreen version={modelVersion}/>
                </Tab>
            </Tabs>
        </ScreenTitle>
    );
};

export default ConfigDevicesScreen;
