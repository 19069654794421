import Http from '../../common/services/Http';
import LanguageDto from './models/LanguageDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class LanguagesService {
    public GetAllForSelectItem() {
        return Http.get<SelectValueLabel[]>('languages/get-All-for-select-item');
    }

    public get (id: string) {
        return Http.get<LanguageDto>(`languages/${id}`);
    }

    public getDefault () {
        return Http.get<LanguageDto>('languages/default');
    }
}

export default new LanguagesService();
