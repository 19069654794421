import { useQuery } from '@tanstack/react-query';

export const useGeoLocator = (lat: number, long: number, lang: string | null = null) => {
    return useQuery({
        queryKey: ['geolocator', `${lat},${long}`],
        // queryFn: () => fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&location_type=ROOFTOP&result_type=street_address&key=AIzaSyBYRtUYW1c5BLiIWO0s3VxVv8ZAzmCVdCY`).then((res) => res.json()),
        queryFn: () => fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`).then((res) => res.json()),
        // queryFn: () => fetch(`https://us1.locationiq.com/v1/reverse?lat=${lat}&lon=${long}&format=json&key=pk.bedcfcc8edcc23aac005219737d079e5`).then((res) => { if (res.status > 400) { throw new Error('Error'); } else { return res.json() } }),
        retryDelay: 2000,
        retry: 10
    });
};

export const addressFormat = (data: any) => {
    if (data?.display_name) { // OSM
        const address = data?.address;
        if (address.country_code === 'fr') {
            const list = [];
            if (address.house_number) {
                list.push(address.house_number);
            }
            if (address.road) {
                list.push(address.road);
            }
            if (address.postcode) {
                list.push(address.postcode);
            }
            if (address.town) {
                list.push(address.town);
            }
            if (address.municipality) {
                list.push(address.municipality);
            }
            if (address.country) {
                list.push(address.country);
            }
            return list.join(', ');
        } else {
            return data?.display_name
        }
    }
    return (data && data.results && data.results.length > 0 ? data.results[0].formatted_address : '');
};
