import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { DriverSearchCriteria } from './models/DriverSearchCriteria';
import { DriverDto } from './models/DriverDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class CompaniesService {
    public getList(criteria: DriverSearchCriteria) {
        return Http.get<Paged<DriverDto>>('drivers', criteria);
    }

    public getById(id: string) {
        return Http.get<DriverDto>('drivers/' + id);
    }

    public getDrivers() {
        return Http.get<SelectValueLabel[]>('drivers/getdrivers');
    }

    public create(model: DriverDto) {
        return Http.post<string>('drivers', model);
    }

    public update(model: DriverDto) {
        return Http.put<string>('drivers/' + model.id, model);
    }

    public remove(model: DriverDto) {
        return Http.put(`drivers/${model.id}/deactivate`, model);
    }
}

export default new CompaniesService();
