import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { SimCardSearchCriteria } from './models/SimCardSearchCriteria';
import { SimCardDto } from './models/SimCardDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class SimCardsService {
    public getList(criteria: SimCardSearchCriteria) {
        return Http.get<Paged<SimCardDto>>('simcards', criteria);
    }

    public getById(id: string) {
        return Http.get<SimCardDto>('simcards/' + id);
    }

    public getSimCards(companyId: string) {
        return Http.get<SelectValueLabel[]>('simcards/getsimcards/' + companyId);
    }

    public create(model: SimCardDto) {
        return Http.post<string>('simcards', model);
    }

    public update(model: SimCardDto) {
        return Http.put<string>('simcards/' + model.id, model);
    }

    public remove(model: SimCardDto) {
        return Http.put(`simcards/${model.id}/deactivate`, model);
    }
}

export default new SimCardsService();
