import { DATE_TIME_FORMAT_DEFAULT, googleMapsQuery } from 'Config';
import DateFormat from 'common/components/dateFormat/dateFormat';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { addressFormat, useGeoLocator } from 'common/services/GeoLocator';
import styles from './AlarmSettingScreen.module.scss';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface Props {
    latitude: number;
    longitude: number;
    triggerDate: Date
}

const TriggerAddress = ({ latitude, longitude, triggerDate }: Props) => {
    const { t } = useTranslation();
    const link = googleMapsQuery(latitude,longitude);

    const renderNoAddress = () => <div>
        <div className={styles.labelAddress}>
            <a href={link}>{t('alarm_settings.notifications.open_location')}
                <span className={styles.linkIcon}>
                    <FaExternalLinkAlt/>
                </span>
                <span className={styles.small}>({`${latitude ?? 0},${longitude ?? 0}`})</span>
            </a>
        </div>
        <div>
            <div className={styles.labelDate}><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={triggerDate} /></div>
        </div>
    </div>
    return renderNoAddress();
}

export default TriggerAddress;
