import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const DistanceFormat: React.FC<NumericFormatProps> = ({ value, ...props }: NumericFormatProps) => {
    const data = Number(value)
    return (
        data > 1 ?
            <NumericFormat
                value={data}
                displayType={props.displayType ?? 'text'}
                thousandSeparator={' '}
                decimalSeparator={','}
                decimalScale={0}
                fixedDecimalScale={false}
                suffix={' km'}
                {...props}
            /> :
            <NumericFormat
                value={data * 1000}
                displayType={props.displayType ?? 'text'}
                thousandSeparator={' '}
                decimalSeparator={','}
                decimalScale={0}
                fixedDecimalScale={false}
                suffix={' m'}
                {...props}
            />
    );
};

export default DistanceFormat;
