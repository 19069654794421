import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedLayout from './common/layouts/authenticatedLayout/AuthenticatedLayout';
import AuthorizeRoute from './common/routes/AuthorizeRoute';
import { ApplicationPaths, LoginActions, LogoutActions } from './Config';
import ForbiddenScreen from './screens/errors/ForbiddenScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import LoginScreen from './screens/auth/LoginScreen';
import LogoutScreen from './screens/auth/LogoutScreen';
import HomeScreen from 'screens/home/HomeScreen';
import CompaniesScreen from 'screens/companies/CompaniesScreen';
import PolicyRoute from 'common/routes/PolicyRoute';
import CompanyScreen from 'screens/company/CompanyScreen';
import AccountScreen from 'screens/account/AccountScreen';
import ConfigDevicesScreen from 'screens/config/device/ConfigDevicesScreen';
import PlanScreen from 'screens/config/plans/PlanScreen';
import SimCardsScreen from 'screens/simCards/SimCardsScreen';
import SimCardScreen from 'screens/simCard/SimCardScreen';
import DevicesScreen from 'screens/devices/DevicesScreen';
import DeviceScreen from 'screens/device/DeviceScreen';
import DriversScreen from 'screens/drivers/DriversScreen';
import DriverScreen from 'screens/driver/DriverScreen';
import VehiclesScreen from 'screens/vehicles/VehiclesScreen';
import VehicleScreen from 'screens/vehicle/VehicleScreen';
import LocationScreen from 'screens/location/LocationScreen';
import SecurityScreen from 'screens/security/SecurityScreen';
import ApiTokensScreen from 'screens/apiTokens/ApiTokensScreen';
import ApiTokenScreen from 'screens/apiToken/ApiTokenScreen';
import TagsScreen from 'screens/tags/TagsScreen';
import TagScreen from 'screens/tag/TagScreen';
import TrailersScreen from 'screens/trailers/TrailersScreen';
import TrailerScreen from 'screens/trailer/TrailerScreen';
import AlarmSettingsScreen from 'screens/alarmSettings/AlarmSettingsScreen';
import AlarmSettingScreen from 'screens/alarmSetting/AlarmSettingScreen';

const HomeRedirection = () => {
    return <Navigate replace={true} to={ApplicationPaths.Home} />;
};

const Router = () => {
    return (

        <BrowserRouter>
            <Routes>
                <Route path="/404" element={<NotFoundScreen />} />
                <Route path="/403" element={<ForbiddenScreen />} />
                <Route path={ApplicationPaths.Login} element={<LoginScreen action={LoginActions.Login} />} />
                <Route path={ApplicationPaths.Reset} element={<LoginScreen action={LoginActions.Reset} />} />
                <Route path={ApplicationPaths.LoginFailed} element={<LoginScreen action={LoginActions.LoginFailed} />} />
                <Route path={ApplicationPaths.LoginCallback} element={<LoginScreen action={LoginActions.LoginCallback} />} />
                <Route path={ApplicationPaths.LogOut} element={<LogoutScreen action={LogoutActions.Logout} />} />
                <Route path={ApplicationPaths.LogOutCallback} element={<LogoutScreen action={LogoutActions.LogoutCallback} />} />
                <Route path='/*' element={
                    <AuthorizeRoute>
                        <AuthenticatedLayout>
                            <Routes>
                                <Route path="/" element={<HomeRedirection />} />
                                <Route path="/home" element={<HomeScreen />} />
                                <Route path="/account/:type" element={<AccountScreen />} />
                                <Route path={'/companies'} element={
                                    <PolicyRoute policies={['COMPANIES_READ']}><CompaniesScreen /></PolicyRoute>}
                                />
                                <Route path={'/company/:type/:id?'} element={
                                    <PolicyRoute policies={['COMPANIES_READ', 'COMPANIES_WRITE']}><CompanyScreen /></PolicyRoute>}
                                />
                                <Route path={'/config-devices'} element={
                                    <PolicyRoute policies={['CONFIG_DEVICE_BRAND_READ', 'CONFIG_DEVICE_BRAND_WRITE']}><ConfigDevicesScreen /></PolicyRoute>}
                                />
                                <Route path={'/plans'} element={
                                    <PolicyRoute policies={['CONFIG_PLANS_READ', 'CONFIG_PLANS_WRITE']}><PlanScreen /></PolicyRoute>}
                                />
                                <Route path={'/simcards'} element={
                                    <PolicyRoute policies={['CONFIG_SIMCARDS_READ', 'MY_SIMCARDS_READ']}><SimCardsScreen /></PolicyRoute>}
                                />
                                <Route path={'/simcard/:type/:id?'} element={
                                    <PolicyRoute policies={['CONFIG_SIMCARDS_READ', 'CONFIG_SIMCARDS_WRITE', 'MY_SIMCARDS_READ']}><SimCardScreen /></PolicyRoute>}
                                />
                                <Route path={'/devices'} element={
                                    <PolicyRoute policies={['CONFIG_DEVICES_READ', 'MY_DEVICES_READ']}><DevicesScreen /></PolicyRoute>}
                                />
                                <Route path={'/device/:type/:id?'} element={
                                    <PolicyRoute policies={['CONFIG_DEVICES_READ', 'CONFIG_DEVICES_WRITE', 'MY_DEVICES_READ']}><DeviceScreen /></PolicyRoute>}
                                />
                                <Route path={'/drivers'} element={
                                    <PolicyRoute policies={['DRIVERS_READ']}><DriversScreen /></PolicyRoute>}
                                />
                                <Route path={'/driver/:type/:id?'} element={
                                    <PolicyRoute policies={['DRIVERS_READ', 'DRIVERS_WRITE']}><DriverScreen /></PolicyRoute>}
                                />
                                <Route path={'/vehicles'} element={
                                    <PolicyRoute policies={['MY_VEHICLES_READ']}><VehiclesScreen /></PolicyRoute>}
                                />
                                <Route path={'/vehicle/:type/:id?'} element={
                                    <PolicyRoute policies={['MY_VEHICLES_READ', 'MY_VEHICLES_WRITE']}><VehicleScreen /></PolicyRoute>}
                                />
                                <Route path={'/trailers'} element={
                                    <PolicyRoute policies={['MY_TRAILERS_READ']}><TrailersScreen /></PolicyRoute>}
                                />
                                <Route path={'/trailer/:type/:id?'} element={
                                    <PolicyRoute policies={['MY_TRAILERS_READ', 'MY_TRAILERS_WRITE']}><TrailerScreen /></PolicyRoute>}
                                />
                                <Route path={'/tags'} element={
                                    <PolicyRoute policies={['CONFIG_TAGS_READ', 'MY_TAGS_READ']}><TagsScreen /></PolicyRoute>}
                                />
                                <Route path={'/tag/:type/:id?'} element={
                                    <PolicyRoute policies={['CONFIG_TAGS_READ', 'CONFIG_TAGS_WRITE', 'MY_TAGS_READ']}><TagScreen /></PolicyRoute>}
                                />
                                <Route path={'/apitokens'} element={
                                    <PolicyRoute policies={['API_TOKENS_READ']}><ApiTokensScreen /></PolicyRoute>}
                                />
                                <Route path={'/apitoken/:type/:id?'} element={
                                    <PolicyRoute policies={['API_TOKENS_READ', 'API_TOKENS_WRITE']}><ApiTokenScreen /></PolicyRoute>}
                                />
                                <Route path={'/location'} element={
                                    <PolicyRoute policies={['LOCATION_READ']}><LocationScreen /></PolicyRoute>}
                                />
                                <Route path={'/alarmsettings'} element={
                                    <PolicyRoute policies={['ALARM_SETTINGS_READ']}><AlarmSettingsScreen /></PolicyRoute>}
                                />
                                <Route path={'/alarmsetting/:type/:id?'} element={
                                    <PolicyRoute policies={['ALARM_SETTINGS_READ', 'ALARM_SETTINGS_WRITE']}><AlarmSettingScreen /></PolicyRoute>}
                                />
                                <Route path={'/security/:tabId?/:type?/:id?'} element={
                                    <PolicyRoute policies={['USERS_READ', 'USERS_WRITE', 'ROLES_READ', 'ROLES_WRITE', 'RULES_READ', 'RULES_WRITE']}>
                                        <SecurityScreen />
                                    </PolicyRoute>}
                                />
                                <Route path="*" element={<Navigate replace={true} to="/404" />} />
                            </Routes>
                        </AuthenticatedLayout>
                    </AuthorizeRoute>
                } />

                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
