import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

const NotFoundScreen: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <h1>404 - {t('messages.page_not_found')}</h1>
        </Container>
    );
};

export default NotFoundScreen;
