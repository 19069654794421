import { Breadcrumb, Button, ButtonGroup, Card, Container, Form, Stack, ToggleButton } from 'react-bootstrap';
import styles from './LocationScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useTranslation } from 'react-i18next';
import Loading from 'common/services/Loading';
import { useEffect, useState } from 'react';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import VehiclesService from 'api/vehicles/VehiclesService';
import Logger from 'common/services/Logger';
import { DATEPICKER_FORMAT_DEFAULT, LOGGER_LOG_TYPE } from 'Config';
import toast from 'react-hot-toast';
import DateTimePicker from 'common/components/dateTimePicker/DateTimePicker';
import VehicleLocationScreen from './vehicles/VehicleLocationScreen';
import TrailersService from 'api/trailers/TrailersService';
import TrailerLocationScreen from './trailers/TrailerLocationScreen';
import AggregateLocationScreen from './aggregate/AggregateLocationScreen';
import HotSpotsService from 'api/hotSpots/HotSpotsService';

type Props = {
};

const LocationScreen: React.FC<Props> = () => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [refresh, setRefresh] = useState<number>(1);
    const [clean, setClean] = useState<number>(1);
    const [vehicleId, setVehicleId] = useState<string>('');
    const [vehicles, setVehicles] = useState<SelectValueLabel[]>([]);
    const [trailers, setTrailers] = useState<SelectValueLabel[]>([]);
    const [trailerId, setTrailerId] = useState<string>('');
    const [hotSpots, setHotSpots] = useState<SelectValueLabel[]>([]);
    const [hotSpotId, setHotSpotId] = useState<string>('');
    const [onRemoveArea, setOnRemoveArea] = useState(false);
    const defaultZoom = 5;
    const [zoomLevel, setZoomLevel] = useState(defaultZoom);
    const types = [
        { name: t('location.vehicle'), value: 'VEHICLE' },
        { name: t('location.trailer'), value: 'TRAILER' },
        { name: t('location.aggregate'), value: 'AGGREGATE' }
    ];
    const [type, setType] = useState('VEHICLE');

    const getData = async () => {
        try {
            Loading.show();
            const vehicles = await VehiclesService.getMyVehicles();
            setVehicles(vehicles);
            const trailers = await TrailersService.getMyTrailers();
            setTrailers(trailers);
            const hotSpots = await HotSpotsService.getMyHotSpots();
            setHotSpots(hotSpots);
            if (hotSpots && hotSpots.length > 0) {
                setHotSpotId(hotSpots[0].value ?? '');
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, []);

    const refreshHotSpots = async (htSpId?: string) => {
        try {
            Loading.show();
            const hotSpots = await HotSpotsService.getMyHotSpots();
            setHotSpots(hotSpots);
            if (htSpId && htSpId !== hotSpotId) {
                setHotSpotId(htSpId);
                setRefresh(refresh + 1);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get hotspots information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const cleanSelectedHotSpot = () => {
        setHotSpotId('');
        setRefresh(refresh + 1);
        setClean(clean + 1);
        setZoomLevel(defaultZoom);
    }

    const hideModalHotSpot = () => {
        setOnRemoveArea(false);
    }

    return (
        <ScreenTitle title={t('location.title')}>
            <Breadcrumb>
                <Breadcrumb.Item active>{t('location.title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Container fluid>
                <Card className={styles.filters}>
                    <Card.Body>
                        <Stack direction="horizontal" gap={3} >
                            <ButtonGroup>
                                {types.map((t, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`typeItem-${idx}`}
                                        type="radio"
                                        variant={'outline-secondary'}
                                        name="typeItem"
                                        value={t.value}
                                        checked={type === t.value}
                                        onChange={(e) => { setType(e.currentTarget.value); setVehicleId(''); setTrailerId(''); setRefresh(refresh + 1); }}>
                                        {t.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            {type === 'VEHICLE' ?
                                <Form.Select
                                    onChange={(e: any) => { setVehicleId(e.target.value); setRefresh(refresh + 1); }}>
                                    <option value={''}>{t('location.select_vehicle')}</option>
                                    {vehicles &&
                                        vehicles.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                    }
                                </Form.Select> :
                                type === 'TRAILER' ?
                                    <Form.Select
                                        onChange={(e: any) => { setTrailerId(e.target.value); setRefresh(refresh + 1); }}>
                                        <option value={''}>{t('location.select_trailer')}</option>
                                        {trailers &&
                                            trailers.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                        }
                                    </Form.Select> :
                                    <>
                                    <Form.Select
                                        key={`hotSpots_${refresh}`}
                                        defaultValue={hotSpotId}
                                        onChange={(e: any) => { setHotSpotId(e.target.value); setRefresh(refresh + 1); }}>
                                        <option value={''}>{t('location.select_hot_spot')}</option>
                                        {hotSpots &&
                                            hotSpots.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                        }
                                    </Form.Select>
                                    <div className="p-2 ms-auto"></div>
                                    <div className="vr" />
                                    {hotSpotId && <Button onClick={() => { setOnRemoveArea(true); } } variant='danger' className="p-2">{t('common.delete')}</Button>}
                                    <Button onClick={cleanSelectedHotSpot} variant='outline-danger' className="p-2">{t('common.clear')}</Button>
                                    </>
                                }
                            {(type === 'VEHICLE' || type === 'TRAILER') && <DateTimePicker dateFormat={DATEPICKER_FORMAT_DEFAULT} customInput={<Form.Control type='text' />} selected={startDate} onChange={(date) => { setStartDate(date); setRefresh(refresh + 1); }} />}
                        </Stack>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid style={{ height: '67vh', flexDirection: 'column' }} key={`${refresh}`}>
                {type === 'VEHICLE' ?
                    <VehicleLocationScreen vehicleId={vehicleId} startDate={startDate} /> :
                    type === 'TRAILER' ?
                        <TrailerLocationScreen trailerId={trailerId} startDate={startDate} /> :
                        <AggregateLocationScreen zoomLevel={zoomLevel} onZoomChange={setZoomLevel} cleanSelected={cleanSelectedHotSpot} hideModal={hideModalHotSpot} onRemove={onRemoveArea} forceRefresh={clean} hotSpotId={hotSpotId} refreshHotSpots={refreshHotSpots} />}

            </Container>
        </ScreenTitle>
    );
}

export default LocationScreen;
