/* eslint-disable multiline-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
import PaginationTextInfo, { PaginationTextInfoProps } from './PaginationTextInfo';
import Pagination, { PaginationProps } from './Pagination';
import styles from './PaginationWithInfo.module.scss';

type Props = PaginationProps & PaginationTextInfoProps;

const PaginationWithInfo = ({ className, ...props }: Props) => props.totalItems > 0 ? (
    <div className={`${styles.container} ${className || ''}`}>
        <PaginationTextInfo {...props} />
        <Pagination {...props} />
    </div>
) : null;

export default PaginationWithInfo;
