/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import ApiTokensService from 'api/apiTokens/ApiTokensService';
import { ApiTokenDto } from 'api/apiTokens/models/ApiTokenDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './ApiTokenScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';

type Props = {
};

const ApiTokenScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasApiTokensWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['API_TOKENS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<ApiTokenDto | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<ApiTokenDto>();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        apiKey: '',
        callbackUrl: '',
        rowVersion: '',
    });

    const getData = async () => {
        try {
            let result: any = { id: '', name: '', apiKey: '' };
            if (id) {
                Loading.show();
                result = await ApiTokensService.getById(id);
                Loading.hide();
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get api token information', error);
            toast.error(t('messages.error_load_info'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/apiToken/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/apiTokens');
        }
    }
    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await ApiTokensService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete api token', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: ApiTokenDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: ApiTokenDto = {
                id: formData.id,
                name: formData.name,
                apiKey: formData.apiKey,
                callbackUrl: formData.callbackUrl,
                rowVersion: formData.rowVersion,
            };
            if (model && model.id) {
                await ApiTokensService.update(model)
                navigateTo('details', id);
            } else if (model) {
                const id = await ApiTokensService.create(model)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the api token with id: ${form.id}`, error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('api_tokens.title')}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('api_tokens.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>
            <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Row>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>{t('api_tokens.name')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="name" type="text" defaultValue={item?.name} readOnly={isDetails} onChange={handleChange} placeholder={t('api_tokens.name')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="callbackUrl">
                            <Form.Label>{t('api_tokens.callback_url')}</Form.Label>
                            <Form.Control name="callbackUrl" type="text" defaultValue={item?.callbackUrl} readOnly={isDetails} onChange={handleChange} placeholder={t('api_tokens.callback_url')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} >
                        <Form.Group className="mb-3" controlId="apiKey">
                            <Form.Label>{t('api_tokens.key')}</Form.Label>
                            <Form.Control name="apiKey" type="text" defaultValue={item?.apiKey} readOnly={true} placeholder={t('api_tokens.key')} />
                        </Form.Group>
                    </Col>
                </Row>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasApiTokensWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as ApiTokenDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && hasApiTokensWritePolicy &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit', item?.id); }}>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && hasApiTokensWritePolicy &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.name ?? '' })} />
        </ScreenTitle>
    );
};

export default ApiTokenScreen;
