/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import TagsService from 'api/tags/TagsService';
import { TagDto } from 'api/tags/models/TagDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './TagScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import CompaniesService from 'api/companies/CompaniesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

type Props = {
};

const TagScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasTagsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CONFIG_TAGS_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<TagDto | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<TagDto>();
    const [companies, setCompanies] = useState<SelectValueLabel[]>([]);
    const [refresh, setRefresh] = useState<number>(1);
    const [formData, setFormData] = useState({
        id: '',
        code: '',
        companyId: '',
        rowVersion: ''
    });

    const getData = async () => {
        try {
            let result: any = { id: '', code: '', companyId: '' };
            Loading.show();
            const companies = await CompaniesService.getCompanies();
            setCompanies(companies);
            if (id) {
                result = await TagsService.getById(id);
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get tag information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
            setRefresh(refresh + 1);
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/tag/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/tags');
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await TagsService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete tag', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: TagDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: TagDto = {
                id: formData.id,
                code: formData.code,
                companyId: formData.companyId,
                rowVersion: formData.rowVersion
            };
            if (model && model.id) {
                await TagsService.update(model)
                navigateTo('details', id);
            } else if (model) {
                const id = await TagsService.create(model)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast(t('messages.tag_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the tag with id: ${form.id}`, error);
                toast.error(t('messages.error_load_info'));
            }
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('tags.title')}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('tags.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>

            <Form onSubmit={onSubmit} noValidate validated={validated} key={refresh}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>{t('tags.code')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="code" type="text" defaultValue={item?.code} readOnly={isDetails || !hasTagsWritePolicy} onChange={handleChange} placeholder={t('tags.code')} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="companyId">
                            <Form.Label>{t('tags.company')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Select required name="companyId" defaultValue={item?.companyId} disabled={isDetails || !hasTagsWritePolicy}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                { companies &&
                                    companies.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasTagsWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as TagDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && (hasTagsWritePolicy) &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => navigateTo('edit', item?.id) }>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && (hasTagsWritePolicy) &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.code ?? '' })} />
        </ScreenTitle>
    );
};

export default TagScreen;
