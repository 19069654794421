import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { DeviceModelSearchCriteria } from './models/DeviceModelSearchCriteria';
import { DeviceModelDto } from './models/DeviceModelDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class DeviceModelsService {
    public getList(criteria: DeviceModelSearchCriteria) {
        return Http.get<Paged<DeviceModelDto>>('devicemodels', criteria);
    }

    public getById(id: string) {
        return Http.get<DeviceModelDto>('devicemodels/' + id);
    }

    public getDeviceModels(brandId: string) {
        return Http.get<SelectValueLabel[]>('devicemodels/getdevicemodels/' + brandId);
    }

    public create(model: DeviceModelDto) {
        return Http.post<string>('devicemodels', model);
    }

    public update(model: DeviceModelDto) {
        console.log(model);
        return Http.put<string>('devicemodels/' + model.id, model);
    }

    public remove(model: DeviceModelDto) {
        return Http.put(`devicemodels/${model.id}/deactivate`, model);
    }
}

export default new DeviceModelsService();
