import Http from '../../common/services/Http';
import { TrackingSearchCriteria } from './models/TrackingSearchCriteria';
import { TrackingDto } from './models/TrackingDto';
import { TrackingRouteDto } from './models/TrackingRouteDto';
import { Paged } from 'api/common/types/Page';
import { AggregateTrackingSearchCriteria } from './models/AggregateTrackingSearchCriteria';
class TrackingsService {
    public getPosition(criteria: TrackingSearchCriteria) {
        return Http.get<TrackingDto>('trackings/getposition', criteria);
    }

    public getRoutes(criteria: TrackingSearchCriteria) {
        return Http.get<TrackingRouteDto[]>('trackings/getroutes', criteria);
    }

    public getWaypoints(criteria: TrackingSearchCriteria) {
        return Http.get<TrackingDto[]>('trackings/getwaypoints', criteria);
    }

    public getAggregatePosition(criteria: AggregateTrackingSearchCriteria) {
        return Http.get<Paged<TrackingDto>>('trackings/load-aggregate-position', criteria);
    }

    public updateAddress(id: number, address: string) {
        return Http.put<string>('trackings/tracking-routes-address/' + id, { id, address });
    }
}

export default new TrackingsService();
