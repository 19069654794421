/* eslint-disable @typescript-eslint/prefer-includes */
/* eslint-disable no-return-assign */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styles from './RulesScreen.module.scss'
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import UsersService from 'api/users/UsersService';
import RolesService from 'api/roles/RolesService';
import RulesService from 'api/rules/RulesService';
import { Button, Form } from 'react-bootstrap';
import { RuleConcatType, RuleDto } from 'api/rules/models/RuleDto';
import { RoleDto } from 'api/roles/models/RoleDto';
import { useDebouncedCallback } from 'use-debounce';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { removeAccents } from 'common/utils/removeAccents';
import { UsersSelectItemDto } from 'api/users/models/UsersSelectItemDto';
import toast from 'react-hot-toast';
import Utils from 'common/services/Utils';

type Props = {
};

const RulesDetails: React.FC<Props> = () => {
    const { t } = useTranslation();
    const [usersList, setUsersList] = useState<UsersSelectItemDto[]>([]);
    const [rulesList, setRulesList] = useState<RuleDto[]>([]);
    const [rolesList, setRolesList] = useState<RoleDto[]>([]);
    const [rule, setRule] = useState<RuleDto>();
    const [filterRule, setFilterRule] = useState<string>('');
    const [filterUser, setFilterUser] = useState<string>('');
    const [filterRole, setFilterRole] = useState<string>('');
    const [usersListFiltered, setUsersListFiltered] = useState<UsersSelectItemDto[]>([]);
    const [rulesListFiltered, setRulesListFiltered] = useState<RuleDto[]>([]);
    const [rolesListFiltered, setRolesListFiltered] = useState<RoleDto[]>([]);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasRulesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['RULES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const users = await UsersService.getAllForSelectItem();
            setUsersList(users);
            const roles = await RolesService.getAllForSelectItem();
            setRolesList(roles);
            const rules = await RulesService.getAllForSelectItem();
            setRulesList(rules);
            if (rules.length >= 1) {
                onRuleSelected(rules[0], users, roles);
            }
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get roles list', error);
            toast.error(t('messages.error_load_info'));
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const roleName = (role: RoleDto): string => {
        if (role.system === true || role.readOnly === true) {
            return t(('security.roles.roles_list.' + role.name) as any)
        } else {
            return role.name;
        }
    }

    const onRuleSelected = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        setRule({ ...rule });
        parseRuleFromExpression(rule, users, roles);
    }

    const parseRuleFromExpression = async (rule: RuleDto, users: UsersSelectItemDto[], roles: RoleDto[]) => {
        const andOrRegex = /\((.*)\)(AND|OR)\((.*)\)/gi;
        const andOrMatch = andOrRegex.exec(rule?.expression);
        if (andOrMatch) {
            // AND / OR
            const andOr = andOrMatch[2];
            if (andOr.toUpperCase() === RuleConcatType.AND) {
                rule.concatType = RuleConcatType.AND;
            } else if (andOr.toUpperCase() === RuleConcatType.OR) {
                rule.concatType = RuleConcatType.OR;
            }
        }
        setRule({ ...rule });
        // Users
        users.forEach(x => x.checked = false);
        const usersExpression = andOrMatch ? andOrMatch[1] : rule.expression?.replace(/\(/g, '').replace(/\)/g, '');
        const usersRegex = /I:([^\s]+)/gi;
        const usersMatchArray = [];
        let usersMatch = usersRegex.exec(usersExpression);
        while (usersMatch != null) {
            usersMatchArray.push(usersMatch[1]);
            usersMatch = usersRegex.exec(usersExpression);
        }
        if (usersMatchArray && usersMatchArray.length > 0) {
            if (usersMatchArray.length === 1 && usersMatchArray[0] === '*') {
                if (filterUser === '') {
                    users.forEach((e: UsersSelectItemDto) => {
                        e.checked = true;
                    });
                } else {
                    users.filter(c => usersListFiltered?.find(e => e.id === c.id) != null).forEach((e: UsersSelectItemDto) => {
                        e.checked = true;
                    });
                }
            } else {
                usersMatchArray.forEach(userName => {
                    const user = users.find(u => u.userName === userName);
                    if (user) {
                        user.checked = true;
                    }
                });
            }
        }
        setUsersList([...users]);
        // Roles
        roles.forEach(x => x.checked = false);
        const rolesExpression = andOrMatch ? andOrMatch[3] : rule.expression?.replace(/\(/g, '').replace(/\)/g, '');
        const rolesRegex = /R:([^\s]+)/gi;
        const rolesMatchArray = [];
        let rolesMatch = rolesRegex.exec(rolesExpression);
        while (rolesMatch != null) {
            rolesMatchArray.push(rolesMatch[1]);
            rolesMatch = rolesRegex.exec(rolesExpression);
        }
        if (rolesMatchArray && rolesMatchArray.length > 0) {
            if (rolesMatchArray.length === 1 && rolesMatchArray[0] === '*') {
                if (filterRole === '') {
                    rolesList.forEach((r: RoleDto) => {
                        r.checked = true;
                    });
                } else {
                    rolesList.filter(c => rolesListFiltered?.find(e => e.id === c.id) != null).forEach((r: RoleDto) => {
                        r.checked = true;
                    });
                }
            } else {
                rolesMatchArray.forEach(userRole => {
                    const role = roles.find(r => r.id === userRole);
                    if (role) {
                        role.checked = true;
                    }
                });
            }
        }
        setRolesList([...roles]);
    }

    const onSave = async () => {
        try {
            Loading.show();
            if (rulesList != null) {
                await RulesService.update(rulesList);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error) {
            toast.error(t('messages.record_save_error'));
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                'Couldn\'t create or update rule',
                error
            );
            Loading.hide();
        }
    };

    const createExpression = (rule: RuleDto) => {
        // Users
        const users = usersList.filter((e: UsersSelectItemDto) => e.checked);
        const usersExpression = users.map((u: UsersSelectItemDto) => 'I:' + u.userName);
        const usersString = isAllUsersChecked(false) ? '(I:*)' : usersExpression && usersExpression.length > 0 ? '(' + usersExpression.join(' OR ') + ')' : '';
        // Roles
        const roles = rolesList.filter((r: RoleDto) => r.checked);
        const rolesExpression = roles ? roles.map((r: RoleDto) => 'R:' + r.id) : [];
        const rolesString = isAllRolesChecked(false) ? '(R:*)' : rolesExpression && rolesExpression.length > 0 ? '(' + rolesExpression.join(' OR ') + ')' : '';
        const andOr = rule.concatType && rule.concatType === RuleConcatType.OR ? RuleConcatType.OR : RuleConcatType.AND;
        // Result
        if (usersString && rolesString) {
            rule.expression = usersString + andOr + rolesString;
        } else if (usersString && !rolesString) {
            rule.expression = usersString;
        } else if (!usersString && rolesString) {
            rule.expression = rolesString;
        } else {
            rule.expression = '';
        }
        setRulesList([
            ...rulesList.map(r => {
                if (r.id === rule.id) {
                    r.expression = rule.expression;
                }
                return r;
            }),
        ]);
    }

    const debouncedRule = useDebouncedCallback((e: any) => {
        setFilterRule(e.target.value);
        const rules = rulesList.filter(item => {
            return removeAccents(t(('security.rules.rules_list.' + item.name) as any)).toLowerCase().indexOf(removeAccents(e.target.value).toLowerCase()) !== -1;
        });
        setRulesListFiltered(rules);
    }, 500);

    const debouncedUser = useDebouncedCallback((e: any) => {
        setFilterUser(e.target.value);
        const users = usersList.filter(item => {
            return removeAccents(item.realName).toLowerCase().indexOf(removeAccents(e.target.value).toLowerCase()) !== -1
                || removeAccents(item.userName).toLowerCase().indexOf(removeAccents(e.target.value).toLowerCase()) !== -1
        });
        setUsersListFiltered(users);
    }, 500);

    const debouncedRole = useDebouncedCallback((e: any) => {
        setFilterRole(e.target.value);
        const roles = rolesList.filter(item => {
            return removeAccents(item.name).toLowerCase().indexOf(removeAccents(e.target.value).toLowerCase()) !== -1
        });
        setRolesListFiltered(roles);
    }, 500);

    const setAnd = () => {
        if (rule) {
            rule.concatType = RuleConcatType.AND;
            setRule(rule);
            createExpression(rule);
            setRule({ ...rule });
        }
    }

    const setOr = () => {
        if (rule) {
            rule.concatType = RuleConcatType.OR;
            setRule(rule);
            createExpression(rule);
            setRule({ ...rule });
        }
    }

    const isAllUsersChecked = (checkFiltered: boolean) => {
        if (checkFiltered) {
            return filterUser !== '' && usersListFiltered.length > 0 && usersListFiltered?.every(_ => _.checked);
        }
        return usersList?.length > 0 && usersList?.every(_ => _.checked);
    }

    const onAllUsersChecked = (checked: boolean) => {
        selectOrUnSelectUsers(checked, filterUser !== '');
        if (rule) {
            createExpression(rule);
        }
    }

    const selectOrUnSelectUsers = async (checked: boolean, filter: boolean) => {
        if (!filter) {
            usersList.forEach((e: UsersSelectItemDto) => {
                e.checked = checked;
            });
        } else {
            usersList.filter(c => usersListFiltered?.find(e => e.id === c.id) != null).forEach((e: UsersSelectItemDto) => {
                e.checked = checked;
            });
        }
        setUsersList([...usersList]);
    }

    const isAllRolesChecked = (checkFiltered: boolean) => {
        if (checkFiltered) {
            return filterRole !== '' && rolesListFiltered.length > 0 && rolesListFiltered?.every(_ => _.checked);
        }
        return rolesList?.length > 0 && rolesList?.every(_ => _.checked);
    }

    const onAllRolesChecked = (checked: boolean) => {
        selectOrUnSelectRoles(checked, filterRole !== '');
        if (rule) {
            createExpression(rule);
        }
    }

    const selectOrUnSelectRoles = (checked: boolean, filter: boolean) => {
        if (!filter) {
            rolesList.forEach((r: RoleDto) => {
                r.checked = checked;
            });
        } else {
            rolesList.filter(c => rolesListFiltered?.find(e => e.id === c.id) != null).forEach((r: RoleDto) => {
                r.checked = checked;
            });
        }
        setRolesList([...rolesList]);
    }

    return (
        <div className={styles.container}>
            <div className={styles.configContainer}>
                <div className={styles.rulesContainer}>
                    <div className={styles.searchContainer}>
                        <Form.Control type="text" className="me-auto" onChange={debouncedRule} placeholder={t('common.search')} />
                    </div>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <td className={styles.tableHeader}>
                                    <span className={styles.tableHeaderTitle}>{t('security.rules.title')}</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {(filterRule !== '' ? rulesListFiltered : rulesList).map((row, rowIndex) => (
                                <tr key={`row-r-${rowIndex}`} className={styles.rowHover} onClick={() => onRuleSelected(row, usersList, rolesList)}>
                                    <td className={`${styles.bodyColumn} ${row.id === rule?.id ? styles.striped : ''}`}>
                                        <div className={styles.ruleContainer}>
                                            <div className={styles.ruleInfo}>{t(('security.rules.rules_list.' + row.name) as any)}</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                { /* ##################  Users ################## */}
                <div className={styles.usersContainer}>
                    {/* <p><strong>{t('users.title')}</strong></p> */}
                    <div className={styles.searchContainer}>
                        <Form.Control type="text" className="me-auto" onChange={debouncedUser} placeholder={t('common.search')} />
                    </div>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <td className={styles.tableHeader}>
                                    <span className={styles.tableHeaderTitle}>{t('security.users.title')}</span>
                                    <Form.Check type="switch" checked={isAllUsersChecked(filterUser !== '')} disabled={!hasRulesWritePolicy} onChange={e => onAllUsersChecked(e.target.checked)} />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {(filterUser !== '' ? usersListFiltered : usersList).map((row, rowIndex) => (
                                <tr key={`row-u-${rowIndex}`} className={hasRulesWritePolicy ? styles.rowHover : undefined}>
                                    <td className={styles.bodyColumn}>
                                        <div className={styles.userContainer}>
                                            <div className={styles.userInfo}>
                                                <span className={styles.name}>{row.realName}</span>
                                                <span className={styles.email}>({row.userName})</span>
                                            </div>
                                            <div className={styles.checkbox}>
                                                <Form.Check type="switch" checked={row.checked || false} key={`row-ch-user-${rowIndex}`} disabled={!hasRulesWritePolicy} onChange={e => {
                                                    setUsersList([
                                                        ...usersList.map(r => {
                                                            if (r.id === row.id) {
                                                                r.checked = e.target.checked;
                                                            }
                                                            return r;
                                                        }),
                                                    ]);
                                                    if (rule) {
                                                        createExpression(rule);
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className={styles.operationsContainer}>
                    <div className={styles.multiButton}>
                        <Button variant={rule?.concatType === RuleConcatType.AND ? 'primary' : 'secondary'} type="button" size="sm" disabled={!hasRulesWritePolicy} className={styles.button} onClick={setAnd}>
                            {t('common.and')}
                        </Button>
                        <Button variant={rule?.concatType === RuleConcatType.OR ? 'primary' : 'secondary'} type="button" size="sm" disabled={!hasRulesWritePolicy} className={styles.button} onClick={setOr}>
                            {t('common.or')}
                        </Button>
                    </div>
                </div>
                { /* ##################  Roles ################## */}
                <div className={styles.rolesContainer}>
                    <div className={styles.searchContainer}>
                        <Form.Control type="text" className="me-auto" onChange={debouncedRole} placeholder={t('common.search')} />
                    </div>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <td className={styles.tableHeader}>
                                    <span className={styles.tableHeaderTitle}>{t('security.roles.title')}</span>
                                    <Form.Check type="switch" checked={isAllRolesChecked(filterRole !== '')} disabled={!hasRulesWritePolicy} onChange={e => onAllRolesChecked(e.target.checked)} />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {(filterRole !== '' ? rolesListFiltered : rolesList).map((row, rowIndex) => (
                                <tr key={`row-u-${rowIndex}`} className={hasRulesWritePolicy ? styles.rowHover : undefined}>
                                    <td className={styles.bodyColumn}>
                                        <div className={styles.roleContainer}>
                                            <div className={styles.roleInfo}>
                                                {roleName(row)}
                                            </div>
                                            <div className={styles.checkbox}>
                                                <Form.Check type="switch" checked={row.checked || false} key={`row-ch-role-${rowIndex}`} disabled={!hasRulesWritePolicy} onChange={e => {
                                                    setRolesList([
                                                        ...rolesList.map(r => {
                                                            if (r.id === row.id) {
                                                                r.checked = e.target.checked;
                                                            }
                                                            return r;
                                                        }),
                                                    ]);
                                                    if (rule) {
                                                        createExpression(rule);
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={styles.expressionContainer}>
                <Form.Control as="textarea" rows={3} className="me-auto" readOnly={true} value={rule?.expression} />
            </div>
            <div className={styles.buttonContainer}>
                {/* {hasRulesWritePolicy && <Button type='button' text={t('common.save')} onClick={() => onSave()} />} */}
                {/* <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>                    */}
                {hasRulesWritePolicy &&
                    <Button variant="primary" type="button" className={styles.button} onClick={() => onSave()}>
                        {t('common.save')}
                    </Button>
                }
            </div>
        </div>
    );
};

export default RulesDetails;
