/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LOGGER_LOG_TYPE } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import DevicesService from 'api/devices/DevicesService';
import { DeviceDto } from 'api/devices/models/DeviceDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './DeviceScreen.module.scss';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import CompaniesService from 'api/companies/CompaniesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import DeviceBrandsService from 'api/deviceBrands/DeviceBrandsService';
import SimCardsService from 'api/simCards/SimCardsService';
import DeviceModelsService from 'api/deviceModels/DeviceModelsService';

type Props = {
};

const DeviceScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasDevicesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CONFIG_DEVICES_WRITE']);
    const hasDevicesRestrictWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['MY_DEVICES_WRITE']);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<DeviceDto | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState<DeviceDto>();
    const [companies, setCompanies] = useState<SelectValueLabel[]>([]);
    const [brands, setBrands] = useState<SelectValueLabel[]>([]);
    const [models, setModels] = useState<SelectValueLabel[]>([]);
    const [simCards, setSimCards] = useState<SelectValueLabel[]>([]);
    const [refresh, setRefresh] = useState<number>(1);
    const [formData, setFormData] = useState({
        id: '',
        code: '',
        companyId: '',
        deviceBrandId: '',
        deviceModelId: '',
        simCardId: '',
        rowVersion: ''
    });

    const getData = async () => {
        try {
            setLoading(true);
            let result: any = { id: '', code: '', companyId: '' };
            Loading.show();
            const companies = await CompaniesService.getCompanies();
            setCompanies(companies);
            const brands = await DeviceBrandsService.getDeviceBrands();
            setBrands(brands);
            if (id) {
                result = await DevicesService.getById(id);
                const smartCards = await SimCardsService.getSimCards(result.companyId ?? '');
                setSimCards(smartCards ?? []);
                const models = await DeviceModelsService.getDeviceModels(result.deviceBrandId ?? '');
                setModels(models ?? []);
            }
            setItem(result);
            setFormData(result);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setLoading(false);
            Loading.hide();
            setRefresh(refresh + 1);
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (formData.deviceBrandId) {
            DeviceModelsService
                .getDeviceModels(formData.deviceBrandId ?? '')
                .then(data => setModels(data));
        } else {
            setModels([]);
        }
    }, [formData.deviceBrandId]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (formData.companyId) {
            SimCardsService
                .getSimCards(formData.companyId ?? '')
                .then(data => setSimCards(data));
        } else {
            setSimCards([]);
        }
    }, [formData.companyId]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/device/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/devices');
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }

        try {
            await DevicesService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete device', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: DeviceDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: DeviceDto = {
                id: formData.id,
                code: formData.code,
                companyId: formData.companyId,
                deviceBrandId: formData.deviceBrandId,
                deviceModelId: formData.deviceModelId,
                simCardId: formData.simCardId,
                rowVersion: formData.rowVersion
            };
            if (model && model.id) {
                await DevicesService.update(model)
                navigateTo('details', id);
            } else if (model) {
                const id = await DevicesService.create(model)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            if (error?.response?.status === 409) {
                toast(t('messages.device_already_exists'));
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the device with id: ${form.id}`, error);
                toast.error(t('messages.error_load_info'));
            }
            Loading.hide();
        }
    };

    return (
        <ScreenTitle title={t('devices.title')}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('devices.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>

            <Form onSubmit={onSubmit} noValidate validated={validated} key={refresh}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>{t('devices.code')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Control required name="code" type="text" defaultValue={item?.code} readOnly={isDetails || !hasDevicesWritePolicy} onChange={handleChange} placeholder={t('devices.code')} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="companyId">
                            <Form.Label>{t('devices.company')}{!isDetails ? '*' : ''}</Form.Label>
                            <Form.Select required name="companyId" defaultValue={item?.companyId} disabled={isDetails || !hasDevicesWritePolicy}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                {companies &&
                                    companies.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="deviceBrandId">
                            <Form.Label>{t('devices.brand')}</Form.Label>
                            <Form.Select name="deviceBrandId" defaultValue={item?.deviceBrandId} disabled={isDetails || !hasDevicesWritePolicy}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                {brands &&
                                    brands.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="deviceModelId">
                            <Form.Label>{t('devices.model')}</Form.Label>
                            <Form.Select name="deviceModelId" defaultValue={item?.deviceModelId} disabled={isDetails || !hasDevicesWritePolicy}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                {models &&
                                    models.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="simCardId">
                            <Form.Label>{t('devices.sim_card')}</Form.Label>
                            <Form.Select name="simCardId" defaultValue={item?.simCardId} disabled={isDetails}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                {simCards &&
                                    simCards.map(c => <option key={c.value} value={c.value}>{c.label}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasDevicesWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as DeviceDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && (hasDevicesWritePolicy || hasDevicesRestrictWritePolicy) &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => navigateTo('edit', item?.id)}>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && (hasDevicesWritePolicy || hasDevicesRestrictWritePolicy) &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.code ?? '' })} />
        </ScreenTitle>
    );
};

export default DeviceScreen;
