import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Http from '../../common/services/Http';
import { UserProfile } from './models/UserProfile';

class AccountService {
    public changeLang(model: SelectValueLabel) {
        return Http.post('account/change-lang', model);
    }

    public changePassword(password: string) {
        return Http.post('account/change-password', { password });
    }

    public changeAccount(model: any) {
        return Http.post('account/change-account', model);
    }

    public profile() {
        return Http.get<UserProfile>('account/profile');
    }
}

export default new AccountService();
