import { useTranslation } from 'react-i18next';
import styles from './PaginationTextInfo.module.scss';

export type PaginationTextInfoProps = {
    pageItems: number;
    totalItems: number;
    itemName: string;
    className?: string;
};

const PaginationTextInfo = ({ pageItems, totalItems, itemName, className }: PaginationTextInfoProps) => {
    const { t } = useTranslation();
    return (
        <div className={`${styles.paginationInfo} ${className || ''}`}>
            {pageItems} {t('pagination.of')} {totalItems} {itemName}
        </div>
    );
}

export default PaginationTextInfo;
