import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { ApiTokenSearchCriteria } from './models/ApiTokenSearchCriteria';
import { ApiTokenDto } from './models/ApiTokenDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class ApiTokensService {
    public getList(criteria: ApiTokenSearchCriteria) {
        return Http.get<Paged<ApiTokenDto>>('apiTokens', criteria);
    }

    public getById(id: string) {
        return Http.get<ApiTokenDto>('apitokens/' + id);
    }

    public getApiTokens() {
        return Http.get<SelectValueLabel[]>('apitokens/getapiTokens');
    }

    public create(model: ApiTokenDto) {
        return Http.post<string>('apitokens', model);
    }

    public update(model: ApiTokenDto) {
        return Http.put<string>('apitokens/' + model.id, model);
    }

    public remove(model: ApiTokenDto) {
        return Http.put(`apitokens/${model.id}/deactivate`, model);
    }
}

export default new ApiTokensService();
