import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { TrailerSearchCriteria } from './models/TrailerSearchCriteria';
import { TrailerDto } from './models/TrailerDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class TrailersService {
    public getList(criteria: TrailerSearchCriteria) {
        return Http.get<Paged<TrailerDto>>('trailers', criteria);
    }

    public getById(id: string) {
        return Http.get<TrailerDto>('trailers/' + id);
    }

    public getMyTrailers() {
        return Http.get<SelectValueLabel[]>('Trailers/getTrailers');
    }

    public create(model: TrailerDto) {
        return Http.post<string>('trailers', model);
    }

    public update(model: TrailerDto) {
        return Http.put<string>('trailers/' + model.id, model);
    }

    public remove(model: TrailerDto) {
        return Http.put(`trailers/${model.id}/deactivate`, model);
    }
}

export default new TrailersService();
