import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { TagSearchCriteria } from './models/TagSearchCriteria';
import { TagDto } from './models/TagDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class TagsService {
    public getList(criteria: TagSearchCriteria) {
        return Http.get<Paged<TagDto>>('tags', criteria);
    }

    public getById(id: string) {
        return Http.get<TagDto>('tags/' + id);
    }

    public getMyTags() {
        return Http.get<SelectValueLabel[]>('tags/getTags');
    }

    public create(model: TagDto) {
        return Http.post<string>('tags', model);
    }

    public update(model: TagDto) {
        return Http.put<string>('tags/' + model.id, model);
    }

    public remove(model: TagDto) {
        return Http.put(`tags/${model.id}/deactivate`, model);
    }
}

export default new TagsService();
